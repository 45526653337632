import React from "react";
import { useTranslation } from "react-i18next";
import flag1 from "../assets/saudi-flag.webp";
import flag2 from "../assets/uaeflag.webp";

const testimonials = [
  {
    position: "Amazon",
    country: "UAE",
    content: "Fabulous service! I was very delighted by the quality, the ease of ordering, delivery and communication. Being a large company I was looking for various printing options and I got them all under one roof. Really appreciate the efforts put in by their team. I am moved by the entire experienced team. Inspiring indeed. Keep up with great work and I would highly recommend to everyone who has a printing need!",
    img: flag2,
  },
  {
    position: "Hyundai",
    country: "UAE",
    img: flag2,
    content: "Very prompt and professional service. Sharp good quality business cards. Company even added on some extra cards free of charge and made the necessary amendments to the file type for best print outcome. Good customer service and our regular print partner",
  },
  {
    position: "General Industries",
    img: flag1,
    country: "SAUDI ARABIA",
    content: "HUMD is highly recommended for all kind promotional and printing requirements. Great quality products at best reasonable prices and on time delivery within short notice is utmost satisfactory. Quality, Price, delivery and professional customer care make HUMD is our favorite.",
  },
];

const Enterprisetestimonials = () => {
  const { t } = useTranslation("enterprisetestimonials");

  return (
    <div>
      <section>
        <div className="container px-4 lg:py-12 md:py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-10">
            <h2 className="text-3xl lg:text-5xl font-bold text-center text-gray-700">{t("What Enterprise Thinks About Us")}</h2>
          </div>
          <div className="flex flex-wrap lg:flex-nowrap justify-center -mx-2">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="w-full lg:w-1/3 mb-6 p-4 shadow-lg border bg-slate-50 mx-2">
                <div className="h-full text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="inline-block w-6 h-6 text-gray-400 my-4" viewBox="0 0 975.036 975.036">
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>{" "}
                  <p className="leading-relaxed lg:h-60 lg:text-center text-left">"{t(testimonial.content)}"</p>
                  <span className="inline-block h-1 w-10 rounded bg-green-500 mt-6 mb-4"></span>
                  <p className="text-gray-500 font-bold">{t(testimonial.position)}</p>
                  <div className="flex mx-auto justify-center pt-2">
                    <img src={testimonial.img} className="w-8" alt={t("flag")} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Enterprisetestimonials;
